// Features.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaShippingFast, FaLock, FaUndo, FaHeadset } from 'react-icons/fa'; // Importing icons from Font Awesome
import './Features.css'; // CSS file for additional styling

const Features = () => {
  const features = [
    {
      icon: <FaShippingFast size={30} className="icon-style" />, // Added icon styling class
      title: 'Free Shipping & Returns',
      text: 'For all orders over $199.00',
    },
    {
      icon: <FaLock size={30} className="icon-style" />,
      title: 'Secure Payment',
      text: 'We ensure secure payment',
    },
    {
      icon: <FaUndo size={30} className="icon-style" />,
      title: 'Money Back Guarantee',
      text: 'Returning money 30 days',
    },
    {
      icon: <FaHeadset size={30} className="icon-style" />,
      title: '24/7 Customer Support',
      text: 'Friendly customer support',
    },
  ];

  return (
    <Container className="py-4">
      <Row className="text-center">
        {features.map((feature, index) => (
          <Col md={6} lg={3} sm={12} key={index} className="mb-3">
            <Card className="feature-card border-0 shadow-sm h-100 d-flex align-items-center p-2">
              <Card.Body className="d-flex align-items-center p-0">
                <div className="icon-container bg-light rounded-circle p-2 me-2">
                  {feature.icon}
                </div>
                <div className="text-start">
                  <Card.Title className="mb-1 small-text">{feature.title}</Card.Title>
                  <Card.Text className="text-muted small-text">{feature.text}</Card.Text>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Features;
