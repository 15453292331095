import React from "react";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import {
  FaPhoneAlt,
  FaUserAlt,
  FaShoppingCart,
  FaGlobe,
  FaWhatsapp,
} from "react-icons/fa";
import HomeIcon from "@mui/icons-material/Home";
import useAuth from "../../hook/useAuth";
import logo from "../../images/AFS LOGO FINAL.png";
import "./Header.css"; // For combined styling, ensure this file includes styles for sticky behavior.

const ModernNavbar = () => {
  const { user, logOut } = useAuth();

  return (
    <header>
      {/* Top Bar with Contact Info and Logo */}
      <div
        className="top-bar d-flex justify-content-center align-items-center me-3"
        style={{
          backgroundColor: "#f8f9fa",
          padding: "10px 20px",
          borderBottom: "1px solid #e9ecef",
        }}
      >
        <div className="me-3">
          <FaWhatsapp className="me-2" />
          +968 78389166
        </div>
        <div className="contact-info d-flex align-items-center ">
          <FaPhoneAlt className="me-2" />
          <span>+968 79181988</span>
        </div>
      </div>

      {/* Main Sticky Navbar */}
      <Navbar
        collapseOnSelect
        expand="lg"
        className="menu-bg py-2 sticky-navbar" // Added sticky-navbar class for better control
        variant="dark"
      >
        <Container>
          <Navbar.Brand
            as={Link}
            to="/home"
            className="d-flex align-items-center"
          >
            <img
              src={logo}
              alt="AFS Logo"
              style={{ height: "30px", marginRight: "10px" }}
            />
            AFS LLC
          </Navbar.Brand>

          {/* Search Bar */}
          <Form className="d-flex ms-auto me-3" style={{ flexGrow: 1 }}>
            <FormControl
              type="search"
              placeholder="Search products"
              className="me-2"
              aria-label="Search"
              style={{ borderRadius: "20px" }}
            />
            <Button variant="outline-light" className="rounded-pill">
              Search
            </Button>
          </Form>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              {/* Navigation Links */}
              <Nav.Link
                as={HashLink}
                className="nav-items text-light"
                to="/home#banner"
              >
                <HomeIcon /> Home
              </Nav.Link>
              <NavDropdown
                title="Shop"
                id="shop-dropdown"
                className="text-light"
              >
                <NavDropdown.Item as={Link} to="/products">
                  Products
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/shop2">
                  Shop 2
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                as={HashLink}
                className="nav-items text-light"
                to="/explore"
              >
                Explore
              </Nav.Link>
              <Nav.Link
                as={Link}
                className="nav-items text-light"
                to="/about-us"
              >
                About Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                className="nav-items text-light"
                to="/contact-us"
              >
                Contact Us
              </Nav.Link>

              {/* User Account & Cart */}
              {user?.email ? (
                <div className="d-flex align-items-center">
                  <Nav.Link
                    as={HashLink}
                    className="nav-items text-light"
                    to="/dashboard"
                  >
                    Dashboard
                  </Nav.Link>
                  <Button
                    className="nav-items-btn text-dark ms-2"
                    onClick={logOut}
                    variant="button"
                  >
                    Logout
                  </Button>
                  <Navbar.Text className="ms-3">
                    <FaUserAlt className="me-1" /> {user?.displayName}
                  </Navbar.Text>
                  <img
                    className="userPhoto ms-3"
                    src={user.photoURL}
                    alt="userImg"
                    style={{ height: "30px", borderRadius: "50%" }}
                  />
                </div>
              ) : (
                <Button
                  className="rounded-pill ms-2"
                  variant="outline-light"
                  as={Link}
                  to="/login"
                >
                  <FaUserAlt className="me-1" /> Login
                </Button>
              )}
              <Nav.Link
                as={Link}
                className="nav-items text-light ms-2"
                to="/cart"
              >
                <FaShoppingCart className="me-1" /> Cart (0)
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default ModernNavbar;
