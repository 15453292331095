// import { CssBaseline, Divider, Grid, Typography } from "@mui/material";
// import { Box } from "@mui/system";
// import React from "react";
// import FacebookIcon from '@mui/icons-material/Facebook';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import YouTubeIcon from '@mui/icons-material/YouTube';
// import PinterestIcon from '@mui/icons-material/Pinterest';
// import { Link } from "react-router-dom";

// const Footer = () => {
//   return (
//     <Box >
//       <CssBaseline />
//       <Grid sx={{ backgroundColor: "#000", width: "auto", px: 8 }}>
//         <Grid>
//           <Typography  sx={{ textAlign: "right", color: "#fff", p: 3 }}>
//                       <FacebookIcon sx={{marginLeft:"10px"}} />
//                       <PinterestIcon sx={{marginLeft:"10px"}}/>
//                       <InstagramIcon sx={{marginLeft:"10px"}}/>
//                       <LinkedInIcon sx={{marginLeft:"10px"}}/>
//                       <TwitterIcon sx={{marginLeft:"10px"}}/>
//                       <YouTubeIcon sx={{marginLeft:"10px"}} />
//           </Typography>
//         </Grid>
//         <Grid
//           container
//           direction="row"
//           justifyContent="space-between"
//           alignItems="flex-start"
//           // alignItems="center"
//           sx={{ display: "flex", px: 3 }}
//         >
//           <Grid>
//             <Box sx={{ width: "100%", maxWidth: 500 }}>
//               <Typography
//                 sx={{ color: "#fff" }}
//                 variant="h6"
//                 component="div"
//                 gutterBottom
//               >
//                 {" "}
//                 About Us
//               </Typography>
//               <Typography
//                 sx={{ color: "#d5d0d0" }}
//                 variant="body2"
//                 gutterBottom
//               >
//                 {" "}
//                 Company Profile
//               </Typography>
//               <Typography
//                 sx={{ color: "#d5d0d0" }}
//                 variant="body2"
//                 gutterBottom
//               >
//                 {" "}
//                 Corporate Governance
//               </Typography>
//               <Typography
//                 sx={{ color: "#d5d0d0" }}
//                 variant="body2"
//                 gutterBottom
//               >
//                 {" "}
//                 Financial Report
//               </Typography>
//               <Typography
//                 sx={{ color: "#d5d0d0" }}
//                 variant="body2"
//                 gutterBottom
//               >
//                 {" "}
//                 Cybersecurity
//               </Typography>
//               <Typography
//                 sx={{ color: "#d5d0d0" }}
//                 variant="body2"
//                 gutterBottom
//               >
//                 {" "}
//                 <Link to="/contactUs"> Contact Us</Link>
//               </Typography>
//             </Box>
//           </Grid>
//           <Grid>
//             <Box sx={{ width: "100%", maxWidth: 500 }}>
//               <Typography
//                 sx={{ color: "#fff" }}
//                 variant="h6"
//                 component="div"
//                 gutterBottom
//               >
//                 {" "}
//                 Newsroom
//               </Typography>
//               <Typography
//                 sx={{ color: "#d5d0d0" }}
//                 variant="body2"
//                 gutterBottom
//               >
//                 {" "}
//                 Blog
//               </Typography>
//               <Typography
//                 sx={{ color: "#d5d0d0" }}
//                 variant="body2"
//                 gutterBottom
//               >
//                 {" "}
//                 Latest News
//               </Typography>
//               <Typography
//                 sx={{ color: "#d5d0d0" }}
//                 variant="body2"
//                 gutterBottom
//               >
//                 {" "}
//                 Success Stories
//               </Typography>
//               <Typography
//                 sx={{ color: "#d5d0d0" }}
//                 variant="body2"
//                 gutterBottom
//               >
//                 {" "}
//                 Press Mentions
//               </Typography>
//             </Box>
//           </Grid>
//           <Grid>
//             <Box sx={{ width: "100%", maxWidth: 500 }}>
//               <Typography
//                 sx={{ color: "#fff" }}
//                 variant="h6"
//                 component="div"
//                 gutterBottom
//               >
//                 {" "}
//                 Event & Showroom
//               </Typography>
//               <Typography
//                 sx={{ color: "#d5d0d0" }}
//                 variant="body2"
//                 gutterBottom
//               >
//                 {" "}
//                 Digital Showroom
//               </Typography>
//               <Typography
//                 sx={{ color: "#d5d0d0" }}
//                 variant="body2"
//                 gutterBottom
//               >
//                 Camera Live
//               </Typography>
//               <Typography
//                 sx={{ color: "#d5d0d0" }}
//                 variant="body2"
//                 gutterBottom
//               >
//                 Event List
//               </Typography>
//             </Box>
//           </Grid>
//           <Grid>
//             <Box sx={{ width: "100%", maxWidth: 500 }}>
//               <Typography
//                 sx={{ color: "#fff" }}
//                 variant="h6"
//                 component="div"
//                 gutterBottom
//               >
//                 {" "}
//                 Quick Links
//               </Typography>
//               <Typography
//                 sx={{ color: "#d5d0d0" }}
//                 variant="body2"
//                 gutterBottom
//               >
//                 {" "}
//                 Core Technologies
//               </Typography>
//               <Typography
//                 sx={{ color: "#d5d0d0" }}
//                 variant="body2"
//                 gutterBottom
//               >
//                 {" "}
//                 Where To Buy
//               </Typography>
//               <Typography
//                 sx={{ color: "#d5d0d0" }}
//                 variant="body2"
//                 gutterBottom
//               >
//                 {" "}
//                 Online Support
//               </Typography>
//             </Box>
//           </Grid>
//           <Grid>
//             <Box sx={{ width: "100%", maxWidth: 500 }}>
//               <Typography
//                 sx={{ color: "#fff" }}
//                 variant="h6"
//                 component="div"
//                 gutterBottom
//               >
//                 CONTACT US
//               </Typography>
//               <Typography
//                 sx={{ color: "#d5d0d0" }}
//                 variant="body2"
//                 gutterBottom
//               >
//                 {" "}
//                 AFS Main Branch
//               </Typography>
//               <Typography
//                 sx={{ color: "#d5d0d0" }}
//                 variant="body2"
//                 gutterBottom
//               >
//                 {" "}
//                 Al Emar Street, PC-122, Maabilah <br /> Muscat
//               </Typography>
//               <Typography
//                 sx={{ color: "#d5d0d0" }}
//                 variant="body2"
//                 gutterBottom
//               >
//                 {" "}
//                 +968 79181988
//               </Typography>
//             </Box>
//           </Grid>
//         </Grid>
//         <Divider sx={{backgroundColor:"#660000",mt:1}} />
//         <Grid>
//           <Box sx={{ justifyContent: "center", p: 2 }}>
//             <Typography
//               variant="h6"
//               sx={{ textAlign: "center", color: "#fff" }}
//             >
//               Advanced Facility Services LLC
//             </Typography>
//             <Typography
//               variant="body2"
//               sx={{ textAlign: "center", color: "#d5d0d0" }}
//             >
//               {" "}
//               © 2021 Advanced Facility Services Digital Technology Co., Ltd.
//               All Rights Reserved.{" "}
//             </Typography>
//           </Box>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default Footer;

// import { CssBaseline, Divider, Grid, Typography } from "@mui/material";
// import { Box } from "@mui/system";
// import React from "react";
// import FacebookIcon from '@mui/icons-material/Facebook';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import YouTubeIcon from '@mui/icons-material/YouTube';
// import PinterestIcon from '@mui/icons-material/Pinterest';
// import HomeIcon from '@mui/icons-material/Home';
// import PhoneIcon from '@mui/icons-material/Phone';
// import EmailIcon from '@mui/icons-material/Email';
// import { Link } from "react-router-dom";
// import MenuIcon from '@mui/icons-material/Menu';
// import { styled } from "@mui/system";
// import { Col, Row } from "react-bootstrap";

// // Apply a custom font family (e.g., 'Roboto', 'Arial', etc.)
// const customFontFamily = "'Roboto', 'Arial', sans-serif";

// const FooterContainer = styled(Box)(({
//   width: '100%',
//   backgroundColor: '#2e2e2e', // Darker background color for better contrast
//   color: '#fff',
//   padding: '2rem 1.5rem', // Added padding
//   boxSizing: 'border-box',
//   fontFamily: customFontFamily,
// }));

// const FooterSection = styled(Grid)(({
//   padding: '1rem',
//   '@media (max-width: 600px)': {
//     padding: '0.5rem',
//   },
// }));

// const SocialMediaIcons = styled(Typography)(({
//   display: 'flex',
//   justifyContent: 'center',
//   gap: '15px',
//   marginBottom: '1.5rem',
//   '& svg': {
//     fontSize: '2rem',
//     color: '#fff',
//     transition: 'color 0.3s, transform 0.3s',
//     '&:hover': {
//       color: '#ff6666',
//       transform: 'scale(1.1)',
//     },
//   },
// }));

// const FooterTitle = styled(Typography)(({
//   color: '#fff',
//   fontWeight: '700', // Increased font weight
//   marginBottom: '1rem',
//   fontSize: '1.5rem',
//   // textAlign: 'center',
// }));

// const FooterTitle2 = styled(Typography)(({
//   color: '#fff',
//   fontWeight: '700', // Increased font weight
//   marginBottom: '1rem',
//   fontSize: '1.5rem',
//   textAlign: 'center',
// }));

// const FooterText = styled(Typography)(({
//   color: '#d5d0d0',
//   marginBottom: '0.75rem',
//   fontSize: '0.875rem',
//   '& a': {
//     color: '#d5d0d0',
//     textDecoration: 'none',
//     fontWeight: '400', // Normal font weight for links
//     '&:hover': {
//       textDecoration: 'underline',
//     },
//   },

// }));

// const IconText = styled(Typography)(({
//   display: 'flex',
//   alignItems: 'center',
//   gap: '10px',
//   fontSize: '0.875rem',
//   marginBottom: '0.75rem',
//   textAlign:'center',
// }));

// const NavigationIcon = styled(MenuIcon)(({
//   fontSize: '2rem',
//   color: '#d5d0d0',
//   cursor: 'pointer',
//   transition: 'color 0.3s, transform 0.3s',
//   '&:hover': {
//     color: '#ff6666',
//     transform: 'scale(1.1)',
//   },
// }));

// const Footer = () => {
//   return (
//     <FooterContainer>
//       <CssBaseline />
//       <Grid container spacing={3}>
//         <Grid item xs={12}>
//           <SocialMediaIcons>
//             <FacebookIcon />
//             <PinterestIcon />
//             <InstagramIcon />
//             <LinkedInIcon />
//             <TwitterIcon />
//             <YouTubeIcon />
//           </SocialMediaIcons>
//         </Grid>
//         <FooterSection item xs={12}>
//   <Box sx={{ textAlign: 'center' }}>
//     <FooterTitle2 variant="h6">CONTACT US</FooterTitle2>
//     <IconText variant="body2" sx={{ textAlign: 'center', display: 'block' }}>
//       <PhoneIcon sx={{ verticalAlign: 'middle', marginRight: '0.5rem' }} />
//       +968 79181988
//     </IconText>
//     <IconText variant="body2" sx={{ textAlign: 'center', display: 'block' }}>
//       <HomeIcon sx={{ verticalAlign: 'middle', marginRight: '0.5rem' }} />
//       AFS Main Branch, Al Emar Street, PC-122, Maabilah, Muscat
//     </IconText>
//     <IconText variant="body2" sx={{ textAlign: 'center', display: 'block' }}>
//       <EmailIcon sx={{ verticalAlign: 'middle', marginRight: '0.5rem' }} />
//       <a href="mailto:sales@afs-om.com" style={{ color: '#d5d0d0', textDecoration: 'none' }}>sales@afs-om.com</a>
//     </IconText>
//   </Box>
// </FooterSection>

//         <FooterSection item xs={12} sm={6} md={3}>
//           <Box>
//             <FooterTitle variant="h6">About Us</FooterTitle>
//             <FooterText variant="body2"><Link to="/about-us">Company Profile</Link></FooterText>
//             <FooterText variant="body2"><Link to="/corporate-governance">Corporate Governance</Link></FooterText>
//             <FooterText variant="body2"><Link to="/financial-report">Financial Report</Link></FooterText>
//             <FooterText variant="body2"><Link to="/contactUs">Contact Us</Link></FooterText>
//           </Box>
//         </FooterSection>
//         <FooterSection item xs={12} sm={6} md={3}>
//           <Box>
//             <FooterTitle variant="h6">Event & Showroom</FooterTitle>
//             <FooterText variant="body2"><Link to="/digital-showroom">Digital Showroom</Link></FooterText>
//             <FooterText variant="body2"><Link to="/camera-live">Camera Live</Link></FooterText>
//             <FooterText variant="body2"><Link to="/event-list">Event List</Link></FooterText>
//           </Box>
//         </FooterSection>
//         <FooterSection item xs={12} sm={6} md={3}>
//           <Box>
//             <FooterTitle2 variant="h6">Policies</FooterTitle2>
//             <Row>
//               <Col xs={12} md={6}>
//                 <FooterText variant="body2"><Link to="/company-policy">Company Policy</Link></FooterText>
//                 <FooterText variant="body2"><Link to="/cookie-policy">Cookie Policy</Link></FooterText>
//                 <FooterText variant="body2"><Link to="/privacy-policy">Privacy Policy</Link></FooterText>
//                 <FooterText variant="body2"><Link to="/warranty-policy">Warranty Policy</Link></FooterText>
//               </Col>
//               <Col xs={12} md={6}>
//                 <FooterText variant="body2"><Link to="/warranty-service">Warranty Service</Link></FooterText>
//                 <FooterText variant="body2"><Link to="/terms-conditions">Terms & Conditions</Link></FooterText>
//                 <FooterText variant="body2"><Link to="/refund-return-policy">Refund & Return Policy</Link></FooterText>
//               </Col>
//             </Row>
//           </Box>
//         </FooterSection>
//         <FooterSection item xs={12} sm={6} md={3}>
//           <Box>
//             <FooterTitle variant="h6">Quick Links</FooterTitle>
//             <FooterText variant="body2"><Link to="/core-technologies">Core Technologies</Link></FooterText>
//             <FooterText variant="body2"><Link to="/where-to-buy">Where To Buy</Link></FooterText>
//             <FooterText variant="body2"><Link to="/online-support">Online Support</Link></FooterText>
//           </Box>
//         </FooterSection>

//         <FooterSection item xs={12} container justifyContent="center" alignItems="center">
//           <NavigationIcon />
//         </FooterSection>
//       </Grid>
//       <Divider sx={{ backgroundColor: "#660000", mt: 2 }} />
//       <Grid container justifyContent="center" sx={{ py: 2 }}>
//         <Box textAlign="center">
//           <Typography variant="h6" sx={{ color: '#fff', fontFamily: customFontFamily }}>Advanced Facility Services LLC</Typography>
//           <Typography variant="body2" sx={{ color: '#d5d0d0', fontFamily: customFontFamily }}>
//             © 2024 Advanced Facility Services Digital Technology Co., Ltd. All Rights Reserved.
//           </Typography>
//         </Box>
//       </Grid>
//     </FooterContainer>
//   );
// };

// export default Footer;

import { CssBaseline, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PinterestIcon from "@mui/icons-material/Pinterest";
import HomeIcon from "@mui/icons-material/Home";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/system";
import { Col, Row } from "react-bootstrap";

// Styled components
const FooterContainer = styled(Box)({
  width: "100%",
  backgroundColor: "#1a1a1a",
  color: "#fff",
  padding: "1rem",
  boxSizing: "border-box",
});

const FooterSection = styled(Grid)({
  padding: "0.5rem",
});

const SocialMediaIcons = styled(Typography)({
  display: "flex",
  justifyContent: "center",
  gap: "10px",
  marginBottom: "1rem",
  "& svg": {
    fontSize: "1.5rem",
    color: "#fff",
    transition: "color 0.3s",
    "&:hover": {
      color: "#ff6666",
    },
  },
});

const FooterTitle = styled(Typography)({
  color: "#fff",
  fontWeight: "bold",
  marginBottom: "0.5rem",
  fontSize: "1.25rem",
});

const FooterTitle2 = styled(Typography)({
  color: "#fff",
  fontWeight: "bold",
  marginBottom: "0.5rem",
  fontSize: "1.25rem",
});
const FooterText = styled(Typography)({
  color: "#d5d0d0",
  marginBottom: "0.5rem",
  fontSize: "0.875rem",
});

const IconText = styled(Typography)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  fontSize: "0.875rem",
});

const NavigationIcon = styled(MenuIcon)({
  fontSize: "1.5rem",
  color: "#d5d0d0",
  cursor: "pointer",
  transition: "color 0.3s",
  "&:hover": {
    color: "#ff6666",
  },
});

const Footer = () => {
  return (
    <FooterContainer>
      <CssBaseline />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SocialMediaIcons>
            <FacebookIcon />
            <PinterestIcon />
            <InstagramIcon />
            <LinkedInIcon />
            <TwitterIcon />
            <YouTubeIcon />
          </SocialMediaIcons>
        </Grid>
        <FooterSection item xs={12} sm={6} md={3}>
          <Box>
            <FooterTitle variant="h6">About Us</FooterTitle>
            <FooterText variant="body2">
              <Link
                to="/about-us"
                style={{ color: "#d5d0d0", textDecoration: "none" }}
              >
                Company Profile
              </Link>
            </FooterText>
            <FooterText variant="body2">
              <Link
                to="/corporate-governance"
                style={{ color: "#d5d0d0", textDecoration: "none" }}
              >
                Corporate Governance
              </Link>
            </FooterText>
            <FooterText variant="body2">
              <Link
                to="/financial-report"
                style={{ color: "#d5d0d0", textDecoration: "none" }}
              >
                Financial Report
              </Link>
            </FooterText>
            <FooterText variant="body2">
              <Link
                to="/contactUs"
                style={{ color: "#d5d0d0", textDecoration: "none" }}
              >
                Contact Us
              </Link>
            </FooterText>
          </Box>
        </FooterSection>
        <FooterSection item xs={12} sm={6} md={3}>
          <Box>
            <FooterTitle variant="h6">Event & Showroom</FooterTitle>
            <FooterText variant="body2">
              <Link
                to="/digital-showroom"
                style={{ color: "#d5d0d0", textDecoration: "none" }}
              >
                Digital Showroom
              </Link>
            </FooterText>
            <FooterText variant="body2">
              <Link
                to="/camera-live"
                style={{ color: "#d5d0d0", textDecoration: "none" }}
              >
                Camera Live
              </Link>
            </FooterText>
            <FooterText variant="body2">
              <Link
                to="/event-list"
                style={{ color: "#d5d0d0", textDecoration: "none" }}
              >
                Event List
              </Link>
            </FooterText>
          </Box>
        </FooterSection>
        <FooterSection item xs={12} sm={6} md={3}>
          <Box>
            <FooterTitle variant="h6" sx={{ textAlign: "center" }}>
              Policies
            </FooterTitle>
            <Row>
              <Col xs={12} md={6}>
                <FooterText variant="body2">
                  <Link
                    to="/company-policy"
                    style={{ color: "#d5d0d0", textDecoration: "none" }}
                  >
                    Company Policy
                  </Link>
                </FooterText>
                <FooterText variant="body2">
                  <Link
                    to="/cookie-policy"
                    style={{ color: "#d5d0d0", textDecoration: "none" }}
                  >
                    Cookie Policy
                  </Link>
                </FooterText>
                <FooterText variant="body2">
                  <Link
                    to="/privacy-policy"
                    style={{ color: "#d5d0d0", textDecoration: "none" }}
                  >
                    Privacy Policy
                  </Link>
                </FooterText>
                <FooterText variant="body2">
                  <Link
                    to="/warranty-policy"
                    style={{ color: "#d5d0d0", textDecoration: "none" }}
                  >
                    Warranty Policy
                  </Link>
                </FooterText>
              </Col>
              <Col xs={12} md={6}>
                <FooterText variant="body2">
                  <Link
                    to="/warranty-service"
                    style={{ color: "#d5d0d0", textDecoration: "none" }}
                  >
                    Warranty Service
                  </Link>
                </FooterText>
                <FooterText variant="body2">
                  <Link
                    to="/terms-conditions"
                    style={{ color: "#d5d0d0", textDecoration: "none" }}
                  >
                    Terms & Conditions
                  </Link>
                </FooterText>
                <FooterText variant="body2">
                  <Link
                    to="/refund-return-policy"
                    style={{ color: "#d5d0d0", textDecoration: "none" }}
                  >
                    Refund & Return Policy
                  </Link>
                </FooterText>
              </Col>
            </Row>
          </Box>
        </FooterSection>
        <FooterSection item xs={12} sm={6} md={3}>
          <Box>
            <FooterTitle variant="h6">Quick Links</FooterTitle>
            <FooterText variant="body2">
              <Link
                to="/core-technologies"
                style={{ color: "#d5d0d0", textDecoration: "none" }}
              >
                Core Technologies
              </Link>
            </FooterText>
            <FooterText variant="body2">
              <Link
                to="/where-to-buy"
                style={{ color: "#d5d0d0", textDecoration: "none" }}
              >
                Where To Buy
              </Link>
            </FooterText>
            <FooterText variant="body2">
              <Link
                to="/online-support"
                style={{ color: "#d5d0d0", textDecoration: "none" }}
              >
                Online Support
              </Link>
            </FooterText>
          </Box>
        </FooterSection>
        <FooterSection item xs={12}>
          <Box sx={{ textAlign: "center" }}>
            <FooterTitle2 variant="h6">CONTACT US</FooterTitle2>
            <IconText
              variant="body2"
              sx={{ textAlign: "center", display: "block" }}
            >
              <PhoneIcon
                sx={{ verticalAlign: "middle", marginRight: "0.5rem" }}
              />
              +968 79181988
            </IconText>
            <IconText
              variant="body2"
              sx={{ textAlign: "center", display: "block" }}
            >
              <HomeIcon
                sx={{ verticalAlign: "middle", marginRight: "0.5rem" }}
              />
              AFS Main Branch, Al Emar Street, PC-122, Maabilah, Muscat
            </IconText>
            <IconText
              variant="body2"
              sx={{ textAlign: "center", display: "block" }}
            >
              <EmailIcon
                sx={{ verticalAlign: "middle", marginRight: "0.5rem" }}
              />
              <a
                href="mailto:sales@afs-om.com"
                style={{ color: "#d5d0d0", textDecoration: "none" }}
              >
                sales@afs-om.com
              </a>
            </IconText>
          </Box>
        </FooterSection>
        {/* <FooterSection item xs={12} container justifyContent="center" alignItems="center">
          <NavigationIcon />
        </FooterSection> */}
      </Grid>
      {/* <Divider sx={{ 
        backgroundColor: "#660000", 
        height: '2px', // Adjust the height as needed
        marginY: 2 // Adjust the margin for spacing
      }} /> */}
      <Grid container justifyContent="center" sx={{ py: 2 }}>
        <Box textAlign="center">
          <Typography variant="h6" sx={{ color: "#fff" }}>
            Advanced Facility Services LLC
          </Typography>
          <Typography variant="body2" sx={{ color: "#d5d0d0" }}>
            © 2024 Advanced Facility Services Digital Technology Co., Ltd. All
            Rights Reserved.
          </Typography>
        </Box>
      </Grid>
    </FooterContainer>
  );
};

export default Footer;
