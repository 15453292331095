// import React from "react";
// import { Carousel } from "react-bootstrap";
// import bannerImg1 from "../../images/banner1.png";
// import bannerImg2 from "../../images/banner2.png";
// import bannerImg3 from "../../images/banner3.png";

// const Banner = () => {
//   return (
//     <Carousel id="banner" variant="dark" fade>
//       <Carousel.Item>
//               <img
//                 className="carousel-img d-block w-100 h-100 rounded"
//                 src={bannerImg1}
//                 alt="First slide"
//               />
//               <Carousel.Caption className="carousel-caption-modern text-light fw-bold">
//                 <h3 className="fw-bold fs-1">Secure Your World with CCTV</h3>
//                 <p>Enhancing security with our advanced CCTV solutions.</p>
//               </Carousel.Caption>
//             </Carousel.Item>
//             <Carousel.Item>
//               <img
//                 className="d-block w-100 h-100 rounded"
//                 src={bannerImg2}
//                 alt="Second slide"
//               />
//               <Carousel.Caption className="carousel-caption-modern text-light fw-bold">
//                 <h3 className="fw-bold fs-1">Access Control Made Easy</h3>
//                 <p>Effortless access control with our innovative sliding gates.</p>
//               </Carousel.Caption>
//             </Carousel.Item>
//             <Carousel.Item>
//               <img
//                 className="d-block  w-100 h-50 rounded"
//                 src={bannerImg3}
//                 alt="Third slide"
//               />
//               <Carousel.Caption className="carousel-caption-modern text-light fw-bold">
//                 <h3 className="fw-bold fs-1">Connecting Your Network</h3>
//                 <p>Building reliable connections with our networking solutions.</p>
//               </Carousel.Caption>
//             </Carousel.Item>
//     </Carousel>
//   );
// };

// export default Banner;

// import React, { useState } from "react";
// import { Carousel, Row, Col, ListGroup, Button, Offcanvas } from "react-bootstrap";
// import bannerImg1 from "../../images/banner1.png";
// import bannerImg2 from "../../images/banner2.png";
// import bannerImg3 from "../../images/banner3.png";
// import { FaNetworkWired, FaVideo, FaHome, FaDoorClosed, FaPlug, FaLaptopCode, FaBuilding } from 'react-icons/fa';

// const BannerWithSidebar = () => {
//   const [showSidebar, setShowSidebar] = useState(false);

//   const handleSidebarToggle = () => setShowSidebar(!showSidebar);

//   const handleItemClick = (item) => {
//     console.log(`Clicked on ${item}`);
//     // Add navigation or other functionality here
//   };

//   return (
//     <>
//       {/* Toggle Button for Sidebar */}
//       <Button variant="primary" onClick={handleSidebarToggle} className="d-md-none">
//         <FaVideo /> Menu
//       </Button>

//       {/* Sidebar Offcanvas */}
//       <Offcanvas show={showSidebar} onHide={handleSidebarToggle} placement="start" className="custom-sidebar">
//         <Offcanvas.Header closeButton>
//           <Offcanvas.Title>Categories</Offcanvas.Title>
//         </Offcanvas.Header>
//         <Offcanvas.Body>
//           <ListGroup>
//             {[

//           { icon: <FaNetworkWired />, text: "Networking" },          // Updated for Networking
//           { icon: <FaVideo />, text: "CCTV Camera" },                // Updated for CCTV Camera
//           { icon: <FaHome />, text: "Home Automation" },             // Updated for Home Automation
//           { icon: <FaDoorClosed />, text: "Sliding Gate Solutions" }, // Updated for Sliding Gate Solutions
//           { icon: <FaPlug />, text: "Accessories" },                 // Updated for Accessories
//           { icon: <FaLaptopCode />, text: "Software" },              // Updated for Software
//           { icon: <FaBuilding />, text: "Enterprise Solutions" },    // Updated for Enterprise Solutions

//             ].map((item, index) => (
//               <ListGroup.Item
//                 key={index}
//                 className="d-flex align-items-center"
//                 onClick={() => handleItemClick(item.text)}
//               >
//                 {item.icon} <span className="ms-2">{item.text}</span>
//               </ListGroup.Item>
//             ))}
//           </ListGroup>
//         </Offcanvas.Body>
//       </Offcanvas>

//       {/* Main Content */}
//       <Row className="gx-0">
//         {/* Sidebar - Visible only on medium screens and above */}
//         <Col xs={8} md={2} className="d-none d-md-block modern-sidebar">
//           <ListGroup className="sidebar-list">
//             {[

//           { icon: <FaNetworkWired />, text: "Networking" },          // Updated for Networking
//           { icon: <FaVideo />, text: "CCTV Camera" },                // Updated for CCTV Camera
//           { icon: <FaHome />, text: "Home Automation" },             // Updated for Home Automation
//           { icon: <FaDoorClosed />, text: "Sliding Gate Solutions" }, // Updated for Sliding Gate Solutions
//           { icon: <FaPlug />, text: "Accessories" },                 // Updated for Accessories
//           { icon: <FaLaptopCode />, text: "Software" },              // Updated for Software
//           { icon: <FaBuilding />, text: "Enterprise Solutions" },    // Updated for Enterprise Solutions

//             ].map((item, index) => (
//               <ListGroup.Item
//                 key={index}
//                 className="d-flex align-items-center"
//                 onClick={() => handleItemClick(item.text)}
//               >
//                 {item.icon} <span className="ms-2">{item.text}</span>
//               </ListGroup.Item>
//             ))}
//           </ListGroup>
//         </Col>

//         {/* Banner Section */}
//         <Col xs={12} md={10}>
//           <Carousel id="banner" fade indicators={false} interval={3000}>
//             <Carousel.Item>
//               <img
//                 className="carousel-img d-block w-100 h-100 rounded"
//                 src={bannerImg1}
//                 alt="First slide"
//               />
//               <Carousel.Caption className="carousel-caption-modern text-light fw-bold">
//                 <h3 className="fw-bold fs-1">Secure Your World with CCTV</h3>
//                 <p>Enhancing security with our advanced CCTV solutions.</p>
//               </Carousel.Caption>
//             </Carousel.Item>
//             <Carousel.Item>
//               <img
//                 className="d-block w-100 h-100 rounded"
//                 src={bannerImg2}
//                 alt="Second slide"
//               />
//               <Carousel.Caption className="carousel-caption-modern text-light fw-bold">
//                 <h3 className="fw-bold fs-1">Access Control Made Easy</h3>
//                 <p>Effortless access control with our innovative sliding gates.</p>
//               </Carousel.Caption>
//             </Carousel.Item>
//             <Carousel.Item>
//               <img
//                 className="d-block  w-100 h-50 rounded"
//                 src={bannerImg3}
//                 alt="Third slide"
//               />
//               <Carousel.Caption className="carousel-caption-modern text-light fw-bold">
//                 <h3 className="fw-bold fs-1">Connecting Your Network</h3>
//                 <p>Building reliable connections with our networking solutions.</p>
//               </Carousel.Caption>
//             </Carousel.Item>
//           </Carousel>
//         </Col>
//       </Row>
//     </>
//   );
// };

// export default BannerWithSidebar;

import React, { useState } from "react";
import {
  Carousel,
  Row,
  Col,
  ListGroup,
  Button,
  Offcanvas,
} from "react-bootstrap";
import {
  FaNetworkWired,
  FaVideo,
  FaHome,
  FaDoorClosed,
  FaPlug,
  FaLaptopCode,
  FaBuilding,
} from "react-icons/fa";

const BannerWithSidebar = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const handleSidebarToggle = () => setShowSidebar(!showSidebar);

  const handleItemClick = (item) => {
    console.log(`Clicked on ${item}`);
    // Add navigation or other functionality here
  };

  return (
    <>
      {/* Toggle Button for Sidebar */}
      <Button
        variant="primary"
        onClick={handleSidebarToggle}
        className="d-md-none"
      >
        <FaVideo /> Menu
      </Button>

      {/* Sidebar Offcanvas */}
      <Offcanvas
        show={showSidebar}
        onHide={handleSidebarToggle}
        placement="start"
        className="custom-sidebar"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Categories</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ListGroup>
            {[
              { icon: <FaNetworkWired />, text: "Networking" },
              { icon: <FaVideo />, text: "CCTV Camera" },
              { icon: <FaHome />, text: "Home Automation" },
              { icon: <FaDoorClosed />, text: "Sliding Gate Solutions" },
              { icon: <FaPlug />, text: "Accessories" },
              { icon: <FaLaptopCode />, text: "Software" },
              { icon: <FaBuilding />, text: "Enterprise Solutions" },
            ].map((item, index) => (
              <ListGroup.Item
                key={index}
                className="d-flex align-items-center"
                onClick={() => handleItemClick(item.text)}
              >
                {item.icon} <span className="ms-2">{item.text}</span>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>

      {/* Main Content */}
      <Row className="gx-0">
        {/* Sidebar - Visible only on medium screens and above */}
        <Col xs={8} md={2} className="d-none d-md-block modern-sidebar">
          <ListGroup className="sidebar-list">
            {[
              { icon: <FaNetworkWired />, text: "Networking" },
              { icon: <FaVideo />, text: "CCTV Camera" },
              { icon: <FaHome />, text: "Home Automation" },
              { icon: <FaDoorClosed />, text: "Sliding Gate Solutions" },
              { icon: <FaPlug />, text: "Accessories" },
              { icon: <FaLaptopCode />, text: "Software" },
              { icon: <FaBuilding />, text: "Enterprise Solutions" },
            ].map((item, index) => (
              <ListGroup.Item
                key={index}
                className="d-flex align-items-center"
                onClick={() => handleItemClick(item.text)}
              >
                {item.icon} <span className="ms-2">{item.text}</span>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>

        {/* Banner Section */}
        <Col xs={12} md={10}>
          <Carousel id="banner" fade indicators={false} interval={3000}>
            <Carousel.Item>
              <img
                className="carousel-img d-block w-100 h-100 rounded"
                src="https://statuskimberley.co.za/wp-content/uploads/2022/08/21.jpg" // CCTV Image
                alt="First slide"
              />
              <Carousel.Caption className="carousel-caption-modern text-light fw-bold">
                <h3 className="fw-bold fs-1">Secure Your World with CCTV</h3>
                <p>Enhancing security with our advanced CCTV solutions.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 h-100 rounded"
                src="https://samsara-web.s3-eu-west-1.amazonaws.com/category/N08/main.jpg" // Sliding Gate Image
                alt="Second slide"
              />
              <Carousel.Caption className="carousel-caption-modern text-light fw-bold">
                <h3 className="fw-bold fs-1">Access Control Made Easy</h3>
                <p>
                  Effortless access control with our innovative sliding gates.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 h-50 rounded"
                src="https://lasucom.edu.ng/wp-content/uploads/2022/06/networking-banner.jpg" // Networking Image
                alt="Third slide"
              />
              <Carousel.Caption className="carousel-caption-modern text-light fw-bold">
                <h3 className="fw-bold fs-1">Connecting Your Network</h3>
                <p>
                  Building reliable connections with our networking solutions.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </>
  );
};

export default BannerWithSidebar;
