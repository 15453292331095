import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import product1 from "../../images/1.jpg";
import product2 from "../../images/2.jpg";
import product3 from "../../images/3.jpg";
import product4 from "../../images/4.jpg";
import product5 from "../../images/5.jpg";
import product6 from "../../images/6.jpg";
import product7 from "../../images/7.jpg";
import product8 from "../../images/8.jpg";
import "./RecentProducts.css"; // Make sure to import your CSS file

const products = [
  product1,
  product2,
  product3,
  product4,
  product5,
  product6,
  product7,
  product8,
];

const RecentProducts = () => {
  return (
    <div className="recent-products-section">
      <Container>
        <div className="section-heading mt-4 text-center">
          <h2>
            Latest <span>Products</span>
          </h2>
          <p>Explore all trending products and grab your best deal.</p>
        </div>
        <Row className="product-row">
          {products.map((product, index) => (
            <Col lg={2} md={3} sm={4} key={index} className="mb-4">
              <img
                src={product}
                alt={`Product ${index + 1}`}
                className="product-img"
              />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default RecentProducts;
