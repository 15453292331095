// import { configureStore } from '@reduxjs/toolkit'
// import reviewsReducer from '../Features/reviewsSlice'

// export const store = configureStore({
//     reducer: {
//         reviews: {
//           reviewsReducer,
//       }
//   }
// })

import { configureStore } from "@reduxjs/toolkit";
import reviewsReducer from "../Features/reviewsSlice"; // Adjust the path as needed

const store = configureStore({
  reducer: {
    reviews: reviewsReducer,
  },
});

export default store;
