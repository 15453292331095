// import { Grid, Typography } from "@mui/material";
// import { Box, styled } from "@mui/system";
// import Button from "@restart/ui/esm/Button";
// import React from "react";
// import aboutImg from "../../images/DUBAI FZE.jpg";

// const Img = styled("img")({
//   margin: "auto",
//   display: "block",
//   maxWidth: "100%",
//   maxHeight: "300px",
//   WebkitBorderRadius: "5px",
// });

// const About = () => {
//   return (
//     <Box sx={{ m: 3, p: 3 ,my:10 }}>
//       <Grid
//         container
//         spacing={{ xs: 2, md: 3 }}
//         columns={{ xs: 4, sm: 8, md: 12, lg: 16 }}
//         sx={{ display: "flex" }}
//       >
//         <Grid item xs={4} sm={8} md={6} lg={8}>
//           <Typography sx={{ color: "#ff6666", fontWeight: 700 }} variant="h4">
//             ABOUT US
//           </Typography>
//           <Typography sx={{ color: " #4d4d00", py: 1 }} variant="body1">
//           About Us
//           Advanced Facility Services LLC is a premier provider of comprehensive facility management solutions, dedicated to enhancing the efficiency, safety, and aesthetic appeal of commercial and residential properties. With a commitment to excellence and a passion for delivering top-notch services, we have established ourselves as a trusted partner for a diverse range of clients.
//           </Typography>
//           <Button className="purchase-btn"  variant="button">
//             {``}
//             Read More
//           </Button>
//         </Grid>
//         <Grid item xs={4} sm={8} md={6} lg={8}>
//           <Img alt="complex" src={aboutImg} />

//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default About;

import { Grid, Typography, Button as MuiButton } from "@mui/material";
import { Box, styled } from "@mui/system";
import React from "react";
import aboutImg from "../../images/DUBAI FZE.jpg";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "300px",
  borderRadius: "10px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
});

const AboutSection = styled(Box)({
  marginBottom: "2rem",
});

const About = () => {
  return (
    <Box sx={{ m: 3, p: 3, my: 10 }}>
      <Grid
        container
        spacing={{ xs: 2, md: 4 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        sx={{ alignItems: "center" }}
      >
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              color: "#ff6666",
              fontWeight: 700,
              mb: 2,
              textAlign: { xs: "center", md: "left" },
            }}
            variant="h4"
          >
            About Us
          </Typography>
          <Typography
            sx={{
              color: "#4d4d00",
              mb: 2,
              lineHeight: 1.6,
              textAlign: { xs: "center", md: "left" },
            }}
            variant="body1"
          >
            Advanced Facility Services LLC is a premier provider of
            comprehensive facility management solutions, dedicated to enhancing
            the efficiency, safety, and aesthetic appeal of commercial and
            residential properties. With a commitment to excellence and a
            passion for delivering top-notch services, we have established
            ourselves as a trusted partner for a diverse range of clients.
          </Typography>
          <AboutSection>
            <Typography
              sx={{
                color: "#333",
                fontWeight: 600,
                mb: 1,
                textAlign: { xs: "center", md: "left" },
              }}
              variant="h6"
            >
              Our Mission
            </Typography>
            <Typography
              sx={{
                color: "#4d4d00",
                mb: 2,
                lineHeight: 1.6,
                textAlign: { xs: "center", md: "left" },
              }}
              variant="body2"
            >
              Our mission is to provide exceptional facility management services
              that exceed our clients' expectations. We strive to create a safe,
              efficient, and aesthetically pleasing environment through
              innovation and dedication.
            </Typography>
          </AboutSection>
          <AboutSection>
            <Typography
              sx={{
                color: "#333",
                fontWeight: 600,
                mb: 1,
                textAlign: { xs: "center", md: "left" },
              }}
              variant="h6"
            >
              Our Values
            </Typography>
            <Typography
              sx={{
                color: "#4d4d00",
                mb: 2,
                lineHeight: 1.6,
                textAlign: { xs: "center", md: "left" },
              }}
              variant="body2"
            >
              We value integrity, transparency, and excellence in everything we
              do. Our team is committed to delivering high-quality services with
              a focus on customer satisfaction, sustainability, and continuous
              improvement.
            </Typography>
          </AboutSection>
          <AboutSection>
            <Typography
              sx={{
                color: "#333",
                fontWeight: 600,
                mb: 1,
                textAlign: { xs: "center", md: "left" },
              }}
              variant="h6"
            >
              Our History
            </Typography>
            <Typography
              sx={{
                color: "#4d4d00",
                mb: 2,
                lineHeight: 1.6,
                textAlign: { xs: "center", md: "left" },
              }}
              variant="body2"
            >
              Established in 2023, Advanced Facility Services LLC has grown from
              a small local provider to a leading player in the facility
              management industry. Our journey has been marked by significant
              milestones, continuous growth, and a steadfast commitment to
              excellence.
            </Typography>
          </AboutSection>
          <AboutSection>
            <Typography
              sx={{
                color: "#333",
                fontWeight: 600,
                mb: 1,
                textAlign: { xs: "center", md: "left" },
              }}
              variant="h6"
            >
              Meet the Team
            </Typography>
            <Typography
              sx={{
                color: "#4d4d00",
                mb: 2,
                lineHeight: 1.6,
                textAlign: { xs: "center", md: "left" },
              }}
              variant="body2"
            >
              Our team is composed of dedicated professionals with extensive
              experience in facility management. We work together to ensure that
              our clients receive the highest level of service and expertise.
            </Typography>
          </AboutSection>
          <MuiButton
            variant="contained"
            color="primary"
            sx={{
              borderRadius: "20px",
              py: 1.5,
              px: 3,
              mt: 2,
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#ff6666",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            Read More
          </MuiButton>
        </Grid>
        <Grid item xs={12} md={6}>
          <Img alt="Company Overview" src={aboutImg} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default About;
