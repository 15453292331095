// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// const axios = require('axios');

// export const productsFetch = createAsyncThunk(
//   "products/productsFetch",
//   async () => {
//     try {
//       const response = await axios.get(
//         "http://localhost:5000/products"
//       );
//       return response.data;
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );

// const initialState = {
//   movies: {},
// };

// export const movieSlice = createSlice({
//   name: "movies",
//   initialState,
//   reducers: {
//     addMovie: (state, { payload }) => {
//       state.movies = payload;
//     },
//   },
// });

// const reviewsSlice = createSlice({
//   name: "reviews",
//   initialState: {
//     latestReviews: [],
//   },
//   reducers: {
//     addReviews: (state, action) => {
//       state.latestReviews = action.payload;
//     },
//     // other reducers
//   },
// });

// export const { addMovie } = movieSlice.actions;
// export const getAllMovie = (state) => state.movies.movies;
// // export default movieSlice.reducer;
// export const { addReviews } = reviewsSlice.actions;
// export default reviewsSlice.reducer;

// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from 'axios';

// // Define the initial state for the reviews slice
// const initialState = {
//   latestReviews: [],
// };

// // Create the reviews slice
// const reviewsSlice = createSlice({
//   name: "reviews",
//   initialState,
//   reducers: {
//     addReviews: (state, action) => {
//       state.latestReviews = action.payload;
//     },
//     // You can add other reducers here
//   },
// });

// // Export the actions created by the slice
// export const { addReviews } = reviewsSlice.actions;

// // Export the reducer to be used in the store
// export default reviewsSlice.reducer;

// // Create an asynchronous thunk to fetch reviews from an API
// export const fetchReviews = createAsyncThunk(
//   'reviews/fetchReviews',
//   async () => {
//     try {
//       const response = await axios.get("http://localhost:5000/happyreview");
//       return response.data;
//     } catch (error) {
//       console.error("Error fetching reviews:", error);
//     }
//   }
// );

// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from "axios";

// export const fetchReviews = createAsyncThunk(
//   'reviews/fetchReviews',
//   async () => {
//     const response = await axios.get('http://localhost:5000/reviews'); // Adjust URL as needed
//     return response.data;
//   }
// );

// const reviewsSlice = createSlice({
//   name: "reviews",
//   initialState: {
//     latestReviews: [],
//   },
//   reducers: {
//     addReviews: (state, action) => {
//       state.latestReviews = action.payload;
//     },
//     // other reducers
//   },
// });

// export const { addReviews } = reviewsSlice.actions;
// export default reviewsSlice.reducer;



import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchReviews = createAsyncThunk(
  "reviews/fetchReviews",
  async () => {
    const response = await axios.get("https://app.afs-om.com/reviews"); // Adjust URL as needed
    return response.data;
  }
);

const reviewsSlice = createSlice({
  name: "reviews",
  initialState: {
    latestReviews: [],
    status: "idle", // idle | loading | succeeded | failed
    error: null,
  },
  reducers: {
    addReviews: (state, action) => {
      state.latestReviews = action.payload;
    },
    // other reducers
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviews.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReviews.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.latestReviews = action.payload;
      })
      .addCase(fetchReviews.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { addReviews } = reviewsSlice.actions;
export default reviewsSlice.reducer;
