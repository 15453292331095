// import React from "react";
// import { Container, Form, Row } from "react-bootstrap";
// // import "./Subscribe.css";

// const Subscribe = () => {
//   return (
//     <div>
//       <Container>
//         <div className="subContainer mt-5">
//           <Row>
//             <div className="col-lg-6 mt-5 ps-4">
//               <p>SUBSCRIBE TO OUR NEWSLETTERS</p>
//               <h3>
//                 Keep up to Date with Our New Collection and Exclusive Offers
//               </h3>
//             </div>
//             <div className="col-lg-6 mt-5 mx-auto">
//               <form className="">
//                 <Form.Group className="mb-3 w-50" controlId="formBasicEmail">
//                   <Form.Label>Email address</Form.Label>
//                   <Form.Control type="email" placeholder="Enter email" />
//                 </Form.Group>

//                 <button className="py-2 px-3" variant="primary" type="submit">
//                   Subscribe
//                 </button>
//               </form>
//             </div>
//           </Row>
//         </div>
//       </Container>
//     </div>
//   );
// };

// export default Subscribe;

import React from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { styled } from "@mui/system";

const Heading = styled("h3")({
  color: "#333",
  fontWeight: 600,
  textAlign: "center",
  marginBottom: "1rem",
});

const SubHeading = styled("p")({
  color: "#555",
  textAlign: "center",
  marginBottom: "2rem",
});

const SubscribeButton = styled(Button)({
  borderRadius: "25px",
  padding: "0.75rem 2rem",
  backgroundColor: "#ff6666",
  borderColor: "#ff6666",
  fontSize: "1rem",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#e55d5d",
    borderColor: "#e55d5d",
  },
});

const Subscribe = () => {
  return (
    <Container fluid className="py-5" style={{ backgroundColor: "#f9f9f9" }}>
      <div className="subContainer mx-auto px-3" style={{ maxWidth: "800px" }}>
        <Row className="justify-content-center">
          <Col lg={6} className="text-center mb-4">
            <SubHeading>SUBSCRIBE TO OUR NEWSLETTERS</SubHeading>
            <Heading>
              Keep up to Date with Our New Collection and Exclusive Offers
            </Heading>
          </Col>
          <Col lg={6}>
            <Form className="d-flex flex-column align-items-center">
              <Form.Group className="mb-3 w-75">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  style={{
                    borderRadius: "25px",
                    padding: "0.75rem 1rem",
                    borderColor: "#ccc",
                  }}
                />
              </Form.Group>
              <SubscribeButton type="submit">Subscribe</SubscribeButton>
            </Form>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Subscribe;
