// import React from 'react';
// import { Container, Row, Col } from 'react-bootstrap';
// import { NavLink } from 'react-router-dom';
// import './Footer2.css'; // Optional CSS for custom styles
// import logo from '../../images/AFS LOGO FINAL.png';

// import { FaFacebook, FaYoutube, FaLinkedin, FaInstagram, FaTwitter, FaWhatsapp } from 'react-icons/fa'; // Importing social icons

// const Footer = () => {
//   return (
//     <footer className="bg-dark text-white py-4 footer-container">
//       <Container>
//         <Row className="mb-4">
//           {/* Contact Information Section */}
//           <Col md={4}>
//             <img src={logo} alt="AFS LLC Logo" className="mb-3" width="150" />
//             <p><strong>E-mail:</strong> info@afs-om.com</p>
//             <p><strong>Head Office:</strong> Al Emart Street, Maabilah Indistrial Area, Seeb, Muscat, Oman.</p>
//             <p><strong>Service Center:</strong> Madinath Sultan Qaboos, Bowsher, Muscat.</p>
//             <p><strong>Warehouse:</strong> Ruwi, Opposite Ok Center, Ruwi, Muscat, Oman.</p>
//           </Col>

//           {/* About Us Section */}
//           <Col md={3}>
//             <h5>About Us</h5>
//             <ul className="list-unstyled">
//               <li><NavLink to="/overview" className="footer-link">Overview</NavLink></li>
//               <li><NavLink to="/our-brands" className="footer-link">Our Brands</NavLink></li>
//               <li><NavLink to="/our-branches" className="footer-link">Our Branches</NavLink></li>
//               <li><NavLink to="/company-policy" className="footer-link">Company Policy</NavLink></li>
//               <li><NavLink to="/management-profile" className="footer-link">Management Profile</NavLink></li>
//               {/* <li><NavLink to="/global-brand-leadership" className="footer-link">Global Brand Leadership</NavLink></li> */}
//               <a href="https://wa.me/96878389166" target="_blank" rel="noopener noreferrer" className="social-icon whatsapp-chat"><FaWhatsapp /></a>
//             </ul>
//           </Col>

//           {/* Company Policy Section */}
//           <Col md={3}>
//             <h5>Company Policy</h5>
//             <ul className="list-unstyled">
//               <li><NavLink to="/cookie-policy" className="footer-link">Cookie Policy</NavLink></li>
//               <li><NavLink to="/privacy-policy" className="footer-link">Privacy Policy</NavLink></li>
//               <li><NavLink to="/warranty-policy" className="footer-link">Warranty Policy</NavLink></li>
//               <li><NavLink to="/warranty-service" className="footer-link">Warranty Service</NavLink></li>
//               <li><NavLink to="/terms-conditions" className="footer-link">Terms & Conditions</NavLink></li>
//               <li><NavLink to="/refund-return-policy" className="footer-link">Refund & Return Policy</NavLink></li>
//             </ul>
//           </Col>

//           {/* Quick Links Section */}
//           <Col md={2}>
//             <h5>Quick Links</h5>
//             <ul className="list-unstyled">
//               <li><NavLink to="/blog" className="footer-link">Blog</NavLink></li>
//               <li><NavLink to="/contact-us" className="footer-link">Contact Us</NavLink></li>
//               <li><NavLink to="/service-center" className="footer-link">Service Center</NavLink></li>
//               <li><NavLink to="/products-hotline" className="footer-link">Products Hotline</NavLink></li>
//               <li><NavLink to="/technical-helplines" className="footer-link">Technical Helplines</NavLink></li>
//             </ul>
//             <div className="mt-3">
//               <p>Call Us: 9 AM - 10 PM</p>
//               <p><strong>+968 78389166</strong> <br /><strong>+968 79181988</strong></p>
//             </div>
//           </Col>
//         </Row>

//         <Row className="justify-content-between align-items-center">
//           {/* Social Media Icons and WhatsApp Chat */}
//           <Col md={6} className="d-flex justify-content-start">
//             <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="social-icon">Facebook</a>
//             <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="social-icon">YouTube</a>
//             <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="social-icon">LinkedIn</a>
//             <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="social-icon">Instagram</a>
//             <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="social-icon">Twitter</a>
//             <a href="https://wa.me/96878389166" target="_blank" rel="noopener noreferrer" className="social-icon whatsapp-chat"><FaWhatsapp /></a>
//           </Col>

//           {/* Copyright Section */}
//           <Col md={6} className="text-md-end">
//             <p className="mb-0">Advanced Facility Services LLC ©2024 - All Rights Reserved.</p>
//           </Col>
//         </Row>
//       </Container>
//     </footer>
//   );
// };

// export default Footer;

// import React from 'react';
// import { Container, Row, Col } from 'react-bootstrap';
// import { NavLink } from 'react-router-dom';
// import { FaFacebook, FaYoutube, FaLinkedin, FaInstagram, FaTwitter, FaWhatsapp } from 'react-icons/fa'; // Importing social icons
// import './Footer2.css';
// import logo from '../../images/AFS LOGO FINAL.png';

// const Footer = () => {
//   return (
//     <footer className="bg-dark text-white py-4 footer-container">
//       <Container>
//         <Row className="mb-4">
//           {/* Contact Information Section */}
//           <Col md={4}>
//             <img src={logo} alt="AFS LLC Logo" className="mb-3" width="150" />
//             <p><strong>E-mail:</strong> info@afs-om.com</p>
//             <p><strong>Head Office:</strong> Al Emart Street, Maabilah Indistrial Area, Seeb, Muscat, Oman.</p>
//             <p><strong>Service Center:</strong> Madinath Sultan Qaboos, Bowsher, Muscat.</p>
//             <p><strong>Warehouse:</strong> Ruwi, Opposite Ok Center, Ruwi, Muscat, Oman.</p>
//           </Col>

//           {/* About Us Section */}
//           <Col md={3}>
//             <h5>About Us</h5>
//             <ul className="list-unstyled">
//               <li><NavLink to="/overview" className="footer-link">Overview</NavLink></li>
//               <li><NavLink to="/our-brands" className="footer-link">Our Brands</NavLink></li>
//               <li><NavLink to="/our-branches" className="footer-link">Our Branches</NavLink></li>
//               <li><NavLink to="/company-policy" className="footer-link">Company Policy</NavLink></li>
//               <li><NavLink to="/management-profile" className="footer-link">Management Profile</NavLink></li>
//               <li><NavLink to="/global-brand-leadership" className="footer-link">Global Brand Leadership</NavLink></li>
//             </ul>
//           </Col>

//           {/* Company Policy Section */}
//           <Col md={3}>
//             <h5>Company Policy</h5>
//             <ul className="list-unstyled">
//               <li><NavLink to="/cookie-policy" className="footer-link">Cookie Policy</NavLink></li>
//               <li><NavLink to="/privacy-policy" className="footer-link">Privacy Policy</NavLink></li>
//               <li><NavLink to="/warranty-policy" className="footer-link">Warranty Policy</NavLink></li>
//               <li><NavLink to="/warranty-service" className="footer-link">Warranty Service</NavLink></li>
//               <li><NavLink to="/terms-conditions" className="footer-link">Terms & Conditions</NavLink></li>
//               <li><NavLink to="/refund-return-policy" className="footer-link">Refund & Return Policy</NavLink></li>
//             </ul>
//           </Col>

//           {/* Quick Links Section */}
//           <Col md={2}>
//             <h5>Quick Links</h5>
//             <ul className="list-unstyled">
//               <li><NavLink to="/blog" className="footer-link">Blog</NavLink></li>
//               <li><NavLink to="/contact-us" className="footer-link">Contact Us</NavLink></li>
//               <li><NavLink to="/service-center" className="footer-link">Service Center</NavLink></li>
//               <li><NavLink to="/products-hotline" className="footer-link">Products Hotline</NavLink></li>
//               <li><NavLink to="/technical-helplines" className="footer-link">Technical Helplines</NavLink></li>
//             </ul>
//             <div className="mt-3">
//               <p>Call Us: 9 AM - 10 PM</p>
//               <p><strong>+968 78389166</strong> <br /><strong>+968 79181988</strong></p>
//             </div>
//           </Col>
//         </Row>

//         <Row className="justify-content-between align-items-center">
//           {/* Social Media Icons and WhatsApp Chat */}
//           <Col md={6} className="d-flex justify-content-start">
//             <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="social-icon"><FaFacebook /></a>
//             <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="social-icon"><FaYoutube /></a>
//             <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="social-icon"><FaLinkedin /></a>
//             <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="social-icon"><FaInstagram /></a>
//             <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="social-icon"><FaTwitter /></a>
//             <a href="https://wa.me/96878389166" target="_blank" rel="noopener noreferrer" className="social-icon whatsapp-chat"><FaWhatsapp /></a>
//           </Col>

//           {/* Copyright Section */}
//           <Col md={6} className="text-md-end">
//             <p className="mb-0">Advanced Facility Services LLC ©2024 - All Rights Reserved.</p>
//           </Col>
//         </Row>
//       </Container>
//     </footer>
//   );
// };

// export default Footer;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
  FaFacebook,
  FaYoutube,
  FaLinkedin,
  FaInstagram,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa"; // Importing social icons
import "./Footer2.css";
import logo from "../../images/AFS LOGO FINAL.png";

const Footer = () => {
  return (
    <footer className=" text-white py-4 footer-container">
      <Container>
        <Row className="mb-4">
          {/* Contact Information Section */}
          <Col md={4}>
            <img src={logo} alt="AFS LLC Logo" className="mb-3" width="150" />
            <p>
              <strong>E-mail:</strong> info@afs-om.com
            </p>
            <p>
              <strong>Showroom:</strong> Building No. 2732, Al Emart Street,
              Maabilah Indistrial Area, Seeb, Muscat, Oman.
            </p>
            <p>
              <strong>Service Center:</strong> Madinath Sultan Qaboos, Bowsher,
              Muscat.
            </p>
            <p>
              <strong>Warehouse:</strong> HSBC Bank Building, Ruwi Street, Near
              OK Roundabout, Ruwi, Muscat, Sultanate of Oman.
            </p>
          </Col>

          {/* About Us Section */}
          <Col md={3}>
            <h5>About Us</h5>
            <ul className="list-unstyled">
              <li>
                <NavLink to="/About-Us" className="footer-link">
                  Overview
                </NavLink>
              </li>
              <li>
                <NavLink to="/our-brands" className="footer-link">
                  Our Brands
                </NavLink>
              </li>
              <li>
                <NavLink to="/our-branches" className="footer-link">
                  Our Branches
                </NavLink>
              </li>
              <li>
                <NavLink to="/company-policy" className="footer-link">
                  Company Policy
                </NavLink>
              </li>
              <li>
                <NavLink to="/management-profile" className="footer-link">
                  Management Profile
                </NavLink>
              </li>
              {/* <li><NavLink to="/global-brand-leadership" className="footer-link">Global Brand Leadership</NavLink></li> */}
            </ul>
          </Col>

          {/* Company Policy Section */}
          <Col md={3}>
            <h5>Company Policy</h5>
            <ul className="list-unstyled">
              <li>
                <NavLink to="/cookie-policy" className="footer-link">
                  Cookie Policy
                </NavLink>
              </li>
              <li>
                <NavLink to="/privacy-policy" className="footer-link">
                  Privacy Policy
                </NavLink>
              </li>
              <li>
                <NavLink to="/warranty-policy" className="footer-link">
                  Warranty Policy
                </NavLink>
              </li>
              <li>
                <NavLink to="/warranty-service" className="footer-link">
                  Warranty Service
                </NavLink>
              </li>
              <li>
                <NavLink to="/terms-conditions" className="footer-link">
                  Terms & Conditions
                </NavLink>
              </li>
              <li>
                <NavLink to="/refund-return-policy" className="footer-link">
                  Refund & Return Policy
                </NavLink>
              </li>
            </ul>
          </Col>

          {/* Quick Links Section */}
          <Col md={2}>
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <NavLink to="/blog" className="footer-link">
                  Blog
                </NavLink>
              </li>
              <li>
                <NavLink to="/contact-us" className="footer-link">
                  Contact Us
                </NavLink>
              </li>
              <li>
                <NavLink to="/service-center" className="footer-link">
                  Service Center
                </NavLink>
              </li>
              <li>
                <NavLink to="/products-hotline" className="footer-link">
                  Products Hotline
                </NavLink>
              </li>
              <li>
                <NavLink to="/technical-helplines" className="footer-link">
                  Technical Helplines
                </NavLink>
              </li>
            </ul>
            <div className="mt-3">
              <p>Call Us: 9 AM - 10 PM</p>
              <p>
                <strong>+968 78389166</strong> <br />
                <strong>+968 79181988</strong>
              </p>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-between align-items-center">
          {/* Social Media Icons and WhatsApp Chat */}
          <Col md={6} className="d-flex justify-content-start">
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <FaFacebook />
            </a>
            <a
              href="https://youtube.com"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <FaYoutube />
            </a>
            <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <FaLinkedin />
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <FaInstagram />
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <FaTwitter />
            </a>
            <a
              href="https://wa.me/96878389166"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon whatsapp-chat"
            >
              <FaWhatsapp />
            </a>
          </Col>

          {/* Copyright Section */}
          <Col md={6} className="text-md-end">
            <p className="mb-0">
              Advanced Facility Services LLC ©2024 - All Rights Reserved.
            </p>
          </Col>
        </Row>
      </Container>

      {/* WhatsApp Live Chat Button */}
      <a
        href="https://wa.me/96878389166"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-live-chat"
      >
        <FaWhatsapp size={30} />
      </a>
    </footer>
  );
};

export default Footer;
