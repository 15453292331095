import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addReviews } from "../../Features/reviewsSlice";
import Products from "../Products/Products";
import Footer from "../Shared/Footer";
import Footer2 from "../Shared/Footer2";
import About from "./About";
import Banner from "./Banner";
import RecentProjects from "./RecentProducts";
import Reviews from "./Reviews";
import Subscribe from "./Subscribe";
import RecentProducts from "./RecentProducts";
import Features from "../../Features/Features";

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch reviews data
    fetch("https://app.afs-om.com/reviews")
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch reviews.");
        }
        return res.json();
      })
      .then((data) => {
        dispatch(addReviews(data)); // Correctly dispatch the action with fetched data
      })
      .catch((error) => console.error("Error fetching reviews:", error)); // Error handling
  }, [dispatch]);

  return (
    <Box>
      <Banner></Banner>
      <Products> </Products>
      <Features></Features>
      <RecentProducts></RecentProducts>
      <Reviews></Reviews>
      <Subscribe></Subscribe>
      <About></About>
      {/* <Footer></Footer> */}
      <Footer2></Footer2>
    </Box>
  );
};

export default Home;
