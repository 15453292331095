// import React from "react";
// import { Col, Image, Row } from "react-bootstrap";
// import contactImg from "../../images/Headquarters 2.jpg";
// import Footer from "../Shared/Footer";

// const ContactUs = () => {
//   return (
//     <div>
//       <div className="container mt-5 mb-5">
//         <h3 className="mb-5 text-primary">CONTACT WITH US</h3>
//         <Row className=" mt-5 contact-section">
//           <Col className="mt-1">
//             <h3>Advanced Facility Services LLC</h3>
//             <Col className="mt-3">
//               <h4 className="text-left mb-3">Address</h4>
//               <p>
//                 PO Box-232, PC-122, Maabilah Industrial Area, Muscat, Sultanate of
//                 Oman
//               </p>
//               <h4>For Sale & General Enquiries</h4>
//               <p>+968 79181988/ +968 93354255</p>
//               <h4>Email</h4>
//               <p>sales@afs-om.com</p>
//             </Col>
//           </Col>
//           <Col className="mb-5">
//             <Image className="w-100 mb-2 pb-5" src={contactImg} alt="" />
//           </Col>
//         </Row>
//       </div>
//       <Footer></Footer>
//     </div>
//   );
// };

// export default ContactUs;

import React from "react";
import { Col, Row, Image, Container } from "react-bootstrap";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import contactImg from "../../images/Headquarters 2.jpg";
import Footer from "../Shared/Footer";
import { styled } from "@mui/system";

const Heading = styled("h3")({
  color: "#ff6666",
  fontWeight: 700,
  textAlign: "center",
  margin: "2rem 0",
});

const SectionTitle = styled("h4")({
  color: "#333",
  fontWeight: 600,
  margin: "1rem 0",
  textDecoration: "underline",
});

const AddressSection = styled("div")({
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  padding: "1.5rem",
  margin: "1rem 0",
});

const Text = styled("p")({
  color: "#555",
  lineHeight: 1.6,
  margin: "0.5rem 0 1rem",
  display: "flex",
  alignItems: "center",
});

const Icon = styled("span")({
  fontSize: "1.2rem",
  color: "#ff6666",
  marginRight: "0.75rem",
});

const ContactImage = styled(Image)({
  borderRadius: "10px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  marginTop: "1rem",
  height: "100%", // Ensure the image height matches the column height
  objectFit: "cover", // Ensure the image covers the column area properly
});

const ContactUs = () => {
  return (
    <>
      <Container
        fluid
        className="py-5"
        style={{ backgroundColor: "#f9f9f9", padding: "2rem" }}
      >
        <Heading>Contact Us</Heading>
        <Row className="justify-content-center" style={{ display: "flex" }}>
          <Col
            md={6}
            className="mb-5"
            style={{
              padding: "1rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AddressSection style={{ flex: 1 }}>
              <SectionTitle>Advanced Facility Services LLC</SectionTitle>
              <SectionTitle>Address</SectionTitle>
              <Text>
                <Icon>
                  <FaMapMarkerAlt />
                </Icon>
                Building No. 2732, Maabilah Industrial Area, PC-122, Seeb,
                Muscat, Sultanate of Oman
              </Text>
              <SectionTitle>For Sale & General Enquiries</SectionTitle>
              <Text>
                <Icon>
                  <FaPhoneAlt />
                </Icon>
                +968 79181988 / +968 93354255
              </Text>
              <SectionTitle>Email</SectionTitle>
              <Text>
                <Icon>
                  <FaEnvelope />
                </Icon>
                sales@afs-om.com
              </Text>
            </AddressSection>
          </Col>
          <Col
            md={6}
            style={{
              padding: "1rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ContactImage className="w-100" src={contactImg} alt="Contact Us" />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ContactUs;
