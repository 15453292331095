// import React, { useEffect, useState } from "react";
// import { Rating } from "@mui/material";
// import { Card, Carousel, Col, Row } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import { Box, Typography, Grid, Paper } from "@mui/material";
// import { fetchReviews } from '../../Features/reviewsSlice'; // Ensure path is correct

// const Reviews = () => {
//   const dispatch = useDispatch();
//   const { latestReviews = [], status, error } = useSelector((state) => state.reviews || {});

//   const [value, setValue] = useState(4.3);
//   const [index, setIndex] = useState(0);

//   useEffect(() => {
//     if (status === 'idle') {
//       dispatch(fetchReviews());
//     }
//   }, [dispatch, status]);

//   const handleSelect = (selectedIndex, _e) => {
//     setIndex(selectedIndex);
//   };

//   if (status === 'loading') {
//     return <div>Loading...</div>;
//   }

//   if (status === 'failed') {
//     return <div>Error: {error}</div>;
//   }

//   return (
//     <div className="news-bg container-fluid">
//       <h2 className="text-center fs-1">
//         Happy Client <span className="title-1">Says</span>
//         <br />
//         <Rating
//           name="simple-controlled"
//           value={value}
//           onChange={(_event, newValue) => {
//             setValue(newValue);
//           }}
//         />
//       </h2>

//       <Carousel
//         className="mt-4 carousel-container"
//         activeIndex={index}
//         onSelect={handleSelect}
//       >
//         {latestReviews.length > 0 && Array.from({ length: Math.ceil(latestReviews.length / 4) }).map((_, i) => (
//           <Carousel.Item key={i}>
//             <Row xs={2} md={4} className="g-2">
//               {latestReviews.slice(i * 4, i * 4 + 4).map((review) => (
//                 <Col key={review._id}>
//                   <Card className="news-card">
//                     <Card.Img
//                       className="img-size mx-auto rounded-circle"
//                       variant="top"
//                       src={review.img}
//                     />
//                     <Card.Body>
//                       <Card.Title className="title-1 text-center">
//                         {review.name}
//                       </Card.Title>
//                       <Card.Text className="text-primary text-center">
//                         {review.comment.slice(0, 120)}
//                       </Card.Text>
//                     </Card.Body>
//                   </Card>
//                 </Col>
//               ))}
//             </Row>
//           </Carousel.Item>
//         ))}
//       </Carousel>

//       <Box sx={{ border: "1px solid blue", mx: 1, my: 10, p: 2 }}>
//         <Grid container sx={{ justifyContent: "center" }}>
//           <Grid item sx={{ textAlign: "center" }}>
//             <Typography sx={{ color: "#0000ff" }} variant="h4">
//               Trusted by our customers
//             </Typography>
//             <Typography sx={{ color: "#ff1a1a" }}>
//               ({latestReviews.length}) customer reviews on TestFreaks
//             </Typography>
//             <Typography sx={{ color: "#0000ff" }}>
//               Very good
//               <Box
//                 sx={{
//                   "& > legend": { mt: 2 },
//                 }}
//               >
//                 <Rating
//                   name="simple-controlled"
//                   value={value}
//                   onChange={(_event, newValue) => {
//                     setValue(newValue);
//                   }}
//                 />
//               </Box>
//             </Typography>
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} sx={{ display: "flex" }}>
//           {latestReviews.map((item) => (
//             <Grid item xs={12} md={4} lg={3} key={item._id}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   "& > :not(style)": {
//                     m: 1,
//                     width: 300,
//                     height: 200,
//                   },
//                 }}
//               >
//                 <Paper sx={{ p: 2 }} variant="outlined" square>
//                   <Typography variant="h6"> {item.reviewerName} </Typography>
//                   <Typography> {item.reviewText.slice(0, 100)} </Typography>
//                   <Typography sx={{ color: "red", fontWeight: "500" }}>
//                     Rating: {item.rating}
//                   </Typography>
//                   <Rating
//                     name="simple-controlled"
//                     value={item.rating}
//                     onChange={(_event, newValue) => {
//                       setValue(newValue);
//                     }}
//                   />
//                 </Paper>
//               </Box>
//             </Grid>
//           ))}
//         </Grid>
//       </Box>
//     </div>
//   );
// };

// export default Reviews;

// import React, { useEffect, useState } from "react";
// import { Rating, Box, Typography, Grid, Paper } from "@mui/material";
// import { Card, Carousel, Col, Row } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchReviews } from "../../Features/reviewsSlice"; // Ensure path is correct

// const Reviews = () => {
//   const dispatch = useDispatch();
//   const {
//     latestReviews = [],
//     status,
//     error,
//   } = useSelector((state) => state.reviews || {});
//   const [value, setValue] = useState(4.3);
//   const [index, setIndex] = useState(0);

//   useEffect(() => {
//     if (status === "idle") {
//       dispatch(fetchReviews());
//     }
//   }, [dispatch, status]);

//   const handleSelect = (selectedIndex, _e) => {
//     setIndex(selectedIndex);
//   };

//   // Debugging logs (remove or comment out in production)
//   console.log("Reviews:", latestReviews);
//   console.log("Status:", status);
//   console.log("Error:", error);

//   if (status === "loading") {
//     return <div>Loading...</div>;
//   }

//   if (status === "failed") {
//     return <div>Error: {error}</div>;
//   }

//   return (
//     <div className="news-bg container-fluid">
//       <h2 className="text-center fs-1">
//         Happy Client <span className="title-1">Says</span>
//         <br />
//         <Rating
//           name="simple-controlled"
//           value={value}
//           onChange={(_event, newValue) => {
//             setValue(newValue);
//           }}
//         />
//       </h2>

//       <Carousel
//         className="mt-4 carousel-container"
//         activeIndex={index}
//         onSelect={handleSelect}
//       >
//         {latestReviews.length > 0 &&
//           Array.from({ length: Math.ceil(latestReviews.length / 4) }).map(
//             (_, i) => (
//               <Carousel.Item key={i}>
//                 <Row xs={2} md={4} className="g-2">
//                   {latestReviews.slice(i * 4, i * 4 + 4).map((review) => (
//                     <Col key={review._id}>
//                       <Card className="news-card">
//                         <Card.Img
//                           className="img-size mx-auto rounded-circle"
//                           variant="top"
//                           src={review.img}
//                         />
//                         <Card.Body>
//                           <Card.Title className="title-1 text-center">
//                             {review.name}
//                           </Card.Title>
//                           <Card.Text className="text-primary text-center">
//                             {review.comment.slice(0, 120)}
//                           </Card.Text>
//                         </Card.Body>
//                       </Card>
//                     </Col>
//                   ))}
//                 </Row>
//               </Carousel.Item>
//             )
//           )}
//       </Carousel>

//       <Box sx={{ border: "1px solid blue", mx: 1, my: 10, p: 2 }}>
//         <Grid container sx={{ justifyContent: "center" }}>
//           <Grid item sx={{ textAlign: "center" }}>
//             <Typography sx={{ color: "#0000ff" }} variant="h4">
//               Trusted by our customers
//             </Typography>
//             <Typography sx={{ color: "#ff1a1a" }}>
//               ({latestReviews.length}) customer reviews on TestFreaks
//             </Typography>
//             <Typography sx={{ color: "#0000ff" }}>
//               Very good
//               <Box sx={{ "& > legend": { mt: 2 } }}>
//                 <Rating
//                   name="simple-controlled"
//                   value={value}
//                   onChange={(_event, newValue) => {
//                     setValue(newValue);
//                   }}
//                 />
//               </Box>
//             </Typography>
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} sx={{ display: "flex" }}>
//           {latestReviews.map((item) => (
//             <Grid item xs={12} md={4} lg={3} key={item._id}>
//               <Box
//                 sx={{
//                   display: "flex",
//                   "& > :not(style)": { m: 1, width: 300, height: 200 },
//                 }}
//               >
//                 <Paper sx={{ p: 2 }} variant="outlined" square>
//                   <Typography variant="h6">{item.reviewerName}</Typography>
//                   <Typography>{item.reviewText.slice(0, 100)}</Typography>
//                   <Typography sx={{ color: "red", fontWeight: "500" }}>
//                     Rating: {item.rating}
//                   </Typography>
//                   <Rating
//                     name="simple-controlled"
//                     value={item.rating}
//                     onChange={(_event, newValue) => {
//                       setValue(newValue);
//                     }}
//                   />
//                 </Paper>
//               </Box>
//             </Grid>
//           ))}
//         </Grid>
//       </Box>
//     </div>
//   );
// };

// export default Reviews;

import React, { useEffect, useState } from "react";
import { Rating, Box, Typography, Grid, Paper } from "@mui/material";
import { Card, Carousel, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchReviews } from "../../Features/reviewsSlice"; // Ensure path is correct

const Reviews = () => {
  const dispatch = useDispatch();
  const {
    latestReviews = [],
    status,
    error,
  } = useSelector((state) => state.reviews || {});
  const [value, setValue] = useState(4.3);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchReviews());
    }
  }, [dispatch, status]);

  const handleSelect = (selectedIndex, _e) => {
    setIndex(selectedIndex);
  };

  // Debugging logs (remove or comment out in production)
  console.log("Reviews:", latestReviews);
  console.log("Status:", status);
  console.log("Error:", error);

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="news-bg container-fluid">
      <h2 className="text-center fs-1">
        Happy Client <span className="title-1">Says</span>
        <br />
        <Rating
          name="simple-controlled"
          value={value}
          onChange={(_event, newValue) => {
            setValue(newValue);
          }}
        />
      </h2>

      <Carousel
        className="mt-4 carousel-container"
        activeIndex={index}
        onSelect={handleSelect}
      >
        {latestReviews.length > 0 &&
          Array.from({ length: Math.ceil(latestReviews.length / 4) }).map(
            (_, i) => (
              <Carousel.Item key={i}>
                <Row xs={2} md={4} className="g-2">
                  {latestReviews.slice(i * 4, i * 4 + 4).map((review) => (
                    <Col key={review._id}>
                      <Card className="news-card">
                        <Card.Img
                          className="img-size mx-auto rounded-circle"
                          variant="top"
                          src={review.img || "defaultImageUrl.jpg"} // Fallback image
                        />
                        <Card.Body>
                          <Card.Title className="title-1 text-center">
                            {review.name || "Anonymous"}
                          </Card.Title>
                          <Card.Text className="text-primary text-center">
                            {review.comment?.slice(0, 120) ||
                              "No comment available."}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Carousel.Item>
            )
          )}
      </Carousel>

      <Box sx={{ border: "1px solid blue", mx: 1, my: 10, p: 2 }}>
        <Grid container sx={{ justifyContent: "center" }}>
          <Grid item sx={{ textAlign: "center" }}>
            <Typography sx={{ color: "#0000ff" }} variant="h4">
              Trusted by our customers
            </Typography>
            <Typography sx={{ color: "#ff1a1a" }}>
              ({latestReviews.length}) customer reviews on TestFreaks
            </Typography>
            <Typography sx={{ color: "#0000ff" }}>
              Very good
              <Box sx={{ "& > legend": { mt: 2 } }}>
                <Rating
                  name="simple-controlled"
                  value={value}
                  onChange={(_event, newValue) => {
                    setValue(newValue);
                  }}
                />
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ display: "flex" }}>
          {latestReviews.map((item) => (
            <Grid item xs={12} md={4} lg={3} key={item._id}>
              <Box
                sx={{
                  display: "flex",
                  "& > :not(style)": { m: 1, width: 300, height: 200 },
                }}
              >
                <Paper sx={{ p: 2 }} variant="outlined" square>
                  <Typography variant="h6">
                    {item.reviewerName || "Anonymous"}
                  </Typography>
                  <Typography>
                    {item.reviewText?.slice(0, 100) ||
                      "No review text available."}
                  </Typography>
                  <Typography sx={{ color: "red", fontWeight: "500" }}>
                    Rating: {item.rating || "N/A"}
                  </Typography>
                  <Rating
                    name="simple-controlled"
                    value={item.rating || 0} // Default to 0 if undefined
                    onChange={(_event, newValue) => {
                      setValue(newValue);
                    }}
                  />
                </Paper>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default Reviews;
